<template>
  <section id="results">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    <b-card no-body>
      <h4 class="mt-2 mr-2 ml-2">Personal data</h4>
      <b-row class="mt-1 mr-1 mb-1 ml-1">
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Name</span>
              </th>
              <td class="pb-50">
                {{ getLogin.firstname }} {{ getLogin.lastname }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="MailIcon" class="mr-75" />
                <span class="font-weight-bold">E-mailaddress</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{getLogin.email}}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <h4 class="mt-2 mr-2 ml-2">Password</h4>
      <validation-observer ref="passwordObserver">
      <b-form @submit.prevent>
        <b-row class="mt-1 mr-1 ml-1">

          <b-col md="4">
            <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
                >
              <b-form-group
                label="Change password"
                label-for="inputPasswordNew"
                label-cols-md="12"
              >
              <b-form-input
                id="inputPasswordNew"
                v-model="newPassword"
                placeholder="New password"
                type="password"
                :class="errors.length > 0 ? 'is-invalid':null"
                />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
                #default="{ errors }"
                name="Password confirmation"
                vid="Password confirmation"
                rules="required|password"
                >
              <b-form-group
                label="Confirm password"
                label-for="inputPasswordNewConfirm"
                label-cols-md="12"
                >
                <b-form-input
                  id="inputPasswordNewConfirm"
                  v-model="newPasswordConfirm"
                  placeholder="Confirm password"
                  type="password"
                  :class="errors.length > 0 ? 'is-invalid':null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-col>
          
        </b-row>
        
        <b-row class="mr-1 mb-1 ml-1">
          <!-- submit and reset -->
          <b-col md="4">
              <b-form-group
                label=""
                label-for="inputButtons"
                label-cols-md="12"
              >            
            <b-button
              id="inputButtons"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="saveNewPassword"
            >
              Save
            </b-button>
              </b-form-group>
          </b-col>          
        </b-row>
      </b-form>
    </validation-observer>
    </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BTable,
  BCard,
  BCardText,
  BForm,
  BFormInput,  
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  data() {
    return {
      isLoading: false,
      newPassword:'',
      newPasswordConfirm:'',
    };
  },
  methods: {
    async saveNewPassword() {
      console.log("saveNewPassword");

      var valid = await this.$refs.passwordObserver.validate();

      if (!valid) {
        alert('Please enter a valid password')
        return;
      }
      else if (this.newPassword!=this.newPasswordConfirm) {
        alert("Password comfirmation is not correct");
        return;
      }
      else {
        const res = await this.callApi('post', '/login/newPassword', {newPassword:this.newPassword});
        this.newPassword = '';
        this.newPasswordConfirm = '';
        var reset = await this.$refs.passwordObserver.reset();
        if (res) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Password successfully changed',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        }
        else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while changing the password',
              icon: 'EditIcon',
              variant: 'error',
            },
          });
        }
      }
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BOverlay,
    BFormGroup,
    vSelect,
    BTable,
    BForm,
    BFormInput,   
    BButton, 
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },  
  mounted() {

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>